import { render, staticRenderFns } from "./doctor-view-full-info.html?vue&type=template&id=63dd4036&scoped=true&"
import script from "./doctor-view-full-info.js?vue&type=script&lang=js&"
export * from "./doctor-view-full-info.js?vue&type=script&lang=js&"
import style0 from "./doctor-view-full-info.scss?vue&type=style&index=0&id=63dd4036&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63dd4036",
  null
  
)

export default component.exports