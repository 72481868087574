/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
export default {
  name: 'doctor-make-favorite',
  components: {},
  props: ['appointmentInfoForFAV', 'page'],
  data () {
    return {
      favFlag:this.appointmentInfoForFAV.is_favourite ? false : true,
      itemId:this.appointmentInfoForFAV.id
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    closePopupOfFav() {
      bus.$emit('appointmentFavModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    confirmFavAppointment(){
      let data = {
        is_favourite : this.favFlag,
        doctor_id:this.itemId
      };
      this.$store.dispatch('makeDoctorFavouriteFun', data).then((res) => {
        toast.success(res.data.msg);
        this.getAppointmentListAgainMethod();
        this.closePopupOfFav();
      }).catch((err) => {
        toast.error(err.data.user_msg || err.data.msg);
      })
    },
    getAppointmentListAgainMethod(){
      if(this.page == 'doctor'){
        this.$store.dispatch('getDoctorsListApiFun')
      }else{
        this.$store.dispatch('getAppointmentListApiFun')
      }
    },
  }
}