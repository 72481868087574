import { render, staticRenderFns } from "./doctor-make-favorite.html?vue&type=template&id=732ea086&scoped=true&"
import script from "./doctor-make-favorite.js?vue&type=script&lang=js&"
export * from "./doctor-make-favorite.js?vue&type=script&lang=js&"
import style0 from "./doctor-make-favorite.scss?vue&type=style&index=0&id=732ea086&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732ea086",
  null
  
)

export default component.exports